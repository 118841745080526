<template>
  <div id="login" style="min-height: calc(100vh - 20px);" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh;" align-v="center">
            <b-col
                lg="6"
                class="d-md-flex align-items-center justify-content-center"
            >
              <b-carousel
                  id="carousel-no-animation"
                  style="text-shadow: 0 0 2px #000;"
                  no-animation
                  indicators
                  class="w-100"
              >
                <b-carousel-slide v-for="(items, index) in Banner" :key="index"
                                  :img-src="items.url"
                ></b-carousel-slide>
              </b-carousel>
            </b-col>
            <b-col lg="6" class="mt-2">
              <div class="mr-4 ml-4">
                <b-row align-h="center">
                  <b-col lg="6" sm="12">
                    <h3 class="mt-2">{{ $t('account.create') }}</h3>
                  </b-col>
                  <b-col lg="5" sm="12" align="right">
                    <language/>
                  </b-col>
                </b-row>
                <b-card no-body class="border mr-4 ml-4">
                  <form-wizard
                      id="register_props"
                      ref="register_props"
                      :color="secondary_color"
                      :title="null"
                      :subtitle="null"
                      :step-size="'sm'"
                      :hide-buttons="true"
                  >
                    <template v-slot:step="props">
                      <wizard-step
                          :tab="props.tab"
                          :transition="props.transition"
                          :index="props.index">
                      </wizard-step>
                    </template>
                    <tab-content :title="$t('account.create')" icon="fas fa-pencil-alt" class="ml-2 mr-2">
                      <b-row align-h="center">
                        <b-col lg="6" sm="12">
                          <input-component
                              :label-input="$t('account.from.fullName')"
                              v-model="$v.form.first_name.$model"
                              required
                              max="50"
                              @keypress="checkIsOnlyTHEnglish"
                              :onlyTHEnglish="true"
                              :state="validateState('first_name')"
                              :require-label="
                        validateState('first_name') !== null &&
                        !$v.form.first_name.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                        <b-col lg="6" sm="12">
                          <input-component
                              :label-input="$t('account.from.lastName')"
                              v-model="$v.form.last_name.$model"
                              required
                              max="50"
                              @keypress="checkIsOnlyTHEnglish"
                              :onlyTHEnglish="true"
                              :state="validateState('last_name')"
                              :require-label="
                        validateState('last_name') !== null &&
                        !$v.form.last_name.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col lg="6" sm="12">
                          <input-component
                              :label-input="$t('account.from.email')"
                              v-model="$v.form.email.$model"
                              required
                              :require-detail="dupEmail"
                              @input="handleSearchInput"
                              :state="validateState('email') && dupEmail === ''"
                              :require-label="
                        validateState('email') !== null &&
                        !$v.form.email.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                        <b-col lg="6" sm="12">
                          <input-component
                              :label-input="$t('account.from.phone')"
                              max="11"
                              required
                              :require-detail="dupPhone"
                              v-model="$v.form.phone.$model"
                              @input="handleSearchInputPhoneDup"
                              :state="validateState('phone') && telState && dupPhone === ''"
                              :require-label="validateState('phone') !== null && !$v.form.phone.required  ? $t('common.requiredField') : !telState ? telInvalid : ''"
                          />
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col lg="6" sm="12">
                          <input-component
                              type="password"
                              icon="password"
                              required
                              :label-input="$t('account.from.password')"
                              v-model="$v.form.password.$model"
                              :state="validateState('password')"
                              :require-label="
                        validateState('password') !== null &&
                        !$v.form.password.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                        <b-col lg="6" sm="12">
                          <input-component
                              type="password"
                              icon="password"
                              required
                              :label-input="$t('account.from.confirmPassword')"
                              v-model="$v.form.confirm_password.$model"
                              :state="validateState('confirm_password')"
                              :require-label="
                        validateState('confirm_password') !== null &&
                        !$v.form.confirm_password.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col lg="12" sm="12">
                          <select-component
                              required
                              :label-select="$t('account.from.whichServiceFrom')"
                              v-model="$v.form.which_service_from.$model"
                              :options="whichServiceList"
                              :state="validateState('which_service_from')"
                              />
                        </b-col>
                      </b-row>
                      <b-row align-h="center" v-if="sales_list.length > 0">
                        <b-col lg="12" sm="12">
                          <select-component :options="sales_list"
                                            :label-select="$t('common.sales_lead')"
                                            v-model="form.sales"
                                            text-field="value"/>
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col style="display: flex;" sm="12" lg="12">
                          <b-form-checkbox
                              id="checkbox-1"
                              v-model="is_term_checkbox"
                              name="checkbox-1"
                          ></b-form-checkbox>
                          <div>
                            {{ $t('account.from.accept') }}
                            <span class="polycy-link" @click="goTerm">
                        {{ $t('account.from.termOfService') }}
                      </span>
                            <span>{{ $t('account.from.and') }}</span>
                            <span class="polycy-link" @click="goPolicy">
                        {{ $t('account.from.policy') }}
                      </span>
                            <span>{{ $t('account.from.already') }}</span>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" lg="10">
                          <div style="margin-top: 15px;">
                            <recaptcha-component
                                @verify="onVerify"
                                :resetToken="resetToken"
                                @token="getTokenReCap($event, 'register')"
                            ></recaptcha-component>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="d-flex justify-content-between mt-5">
                        <div>
                          <b-button class="button-wizard" variant="outline" @click="actionStep('sign-in')">
                            <i class="fas fa-angle-left"/>
                            {{ $t('login.signIn') }}
                          </b-button>
                        </div>
                        <div>
                          <b-button class="button-wizard" variant="primary" @click="actionStep('next')"
                          :disabled="isInvalid || is_create_register || dupEmail !== '' && dupEmail !== null"
                          >
                            {{ $t('common.next') }}
                            <b-spinner small v-if="is_create_register" class="mt-1"/>
                            <i class="fas fa-angle-right"/>
                          </b-button>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content :title="$t('account.verify')" icon="fas fa-user-circle" class="ml-2 mr-2">
                      <!-- Request Verify OTP BY PHONE -->
                      <div v-if="verify_type === 'phone'">
                        <div>
                          <div v-if="!is_loading_setting">
                            <div class="text-left">{{ $t('account.verify_otp.receive_otp_phone') }}</div>
                              <div v-if="verify_type === 'phone'" class="mt-3">
                              <input-component :label-input="$t('account.verify_otp.phone_otp')" v-model="form.phone"
                                               disabled-type/>
                              </div>
                          </div>
                          <div v-else class="my-5">
                            <b-spinner style="width: 1.5rem; height: 1.5rem" class="align-middle mr-2"/>
                          </div>
                          <div v-if="is_req_otp" class="mt-4">
                            <div class="text-left">
                              <div class="text-secondary">{{ $t('account.verify_otp.otp_code') }}</div>
                            </div>
                            <div class="mb-4">
                              <otp-input
                                  ref="otpInput"
                                  id="otpInput"
                                  :input-classes="error_req_otp ?  'otp-input-register-error':  'otp-input-register'"
                                  separator=" "
                                  :num-inputs="6"
                                  :should-auto-focus="true"
                                  :is-input-num="true"
                                  input-type="number"
                                  @on-change="onChangedHandlerOTP"
                                  @on-complete="verifyOTP"
                              />
                              <div class="text-left text-danger mt-2" v-if="error_req_otp">
                                {{ error_req_otp }}
                              </div>
                            </div>
                            <div class="text-center mb-5">
                              <div>
                                {{ $t('account.verify_otp.enter_otp_code') }}
                                {{ $t('SMS') }}
                                {{ $t('account.verify_otp.verify_identity') }}
                              </div>
                              <div class="text-primary">
                                Ref Code : <span>{{ ref_code }}</span> (
                                {{ $t('account.verify_otp.exp_code') + ' ' + rate_time + ' ' + $t('common.minute') }} )
                              </div>
                            </div>
                          </div>
                          <div class="m-2">
                            <b-row align-h="start"
                                  v-show="is_req_otp && time === '00:00' || !is_req_otp && time === '00:00' && !resetTokenOTP && !tokenRecapOTP && !back_step">
                              <recaptcha-component
                                  @verify="onVerify"
                                  :resetToken="resetTokenOTP"
                                  @token="getTokenReCap($event, 'resend')"
                              />
                            </b-row>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mt-4">
                          <div>
                            <b-button class="button-wizard" variant="outline" @click="actionStep('back')"
                              :disabled="back_step_otp">
                              <i class="fas fa-angle-left"/>
                              {{ $t('common.previous') }}
                            </b-button>
                          </div>
                          <div>
                            <b-button
                                v-if="is_req_otp && time !== '00:00' || !is_req_otp && (resetTokenOTP && !tokenRecapOTP)"
                                class="button-wizard" variant="primary"
                                disabled>
                              {{ $t('account.verify_otp.new_req_otp') + " (" + time + ")" }}
                            </b-button>
                            <b-button v-else class="button-wizard" variant="primary" @click="actionStep('req-otp')"
                                      :disabled="isInvalidReqOtp || loading_req_otp ">
                              {{
                                is_req_otp && time === '00:00' ? $t('account.verify_otp.new_req_otp') :
                                    $t('account.verify_otp.receive_otp')
                              }}
                              <b-spinner small v-if="loading_req_otp"/>
                            </b-button>
                          </div>
                        </div>
                      </div>
                       <!-- Request Verify EMAIL Update EMAIL -->
                       <div v-if="verify_type === 'email_update'">
                        <div>
                          <div v-if="isShowTextEmailVerify" class="text-left">
                            {{  $t('account.verify_email.verify_email') }}
                          </div>
                          <div v-else class="text-left">
                            {{ headerTab }}
                          </div>
                          <div class="mt-3">
                            <input-component
                              :label-input="$t('account.from.email')"
                              v-model="$v.form.email.$model"
                              required
                              :state="validateState('email') && errorEmail === ''"
                              :require-label="validateState('email') !== null && !$v.form.email.required 
                                              ? $t('common.requiredField') : !$v.form.email.email
                                              ? $t('account.verify_email.invalid_email') : errorEmail !== '' 
                                              ?  errorEmail : ''"
                              :disabled-type = "!isEmailDisabled"
                              @input="handleEmailInput"
                              />
                            <div v-if="isShowEdit" class="d-flex justify-content-end input-box" style="height: 30px">
                              <span v-if="!isEmailDisabled && !isReqEmailOtp"
                                class="edit-txt"
                                @click="toggleEdit()"
                                >
                                {{ $t('account.verify_email.edit_email') }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="isShowTextEmailVerify" class="text-center mb-5 mt-4">
                          <div>{{ $t('account.verify_email.verify_email_text') }}</div>
                          <div>{{ $t('account.verify_email.verify_email_confirm') }}</div>
                        </div>
                        <hr class="my-4">
                        <div v-if="isEmailDisabled" class="d-flex justify-content-between mt-4">
                          <div>
                            <b-button
                              class="button-wizard px-4"
                              variant="outline"
                              :disabled="isReqEmailOtp"
                              @click="cancelUpdate()"
                            >
                              {{ $t('account.verify_email.cancel_update') }}
                            </b-button>
                          </div>
                          <div>
                            <b-button
                              class="button-wizard px-4"
                              variant="primary"
                              :disabled = "errorEmail !== '' && errorEmail !== null || isReqEmailOtp"
                              @click="updateEmail()"
                            >
                              {{ $t('account.verify_email.update_email') }}
                              <b-spinner small v-if="isReqEmailOtp"/>
                            </b-button>
                          </div>
                        </div>
                        <div v-else class="d-flex justify-content-center mt-4">
                          <div>
                            <b-button
                              class="button-wizard px-5" 
                              variant="primary"
                              :disabled = "isReqEmailOtp"
                              @click="verifyEmail(true)"
                            >
                              {{ isReqEmailOtp ? $t('account.verify_email.new_req_email') + " (" + timerShower + ")"
                                                : $t('account.verify_email.new_req_email') 
                              }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                       <!-- Verify OTP BY EMAIL Success -->
                       <div v-if="verify_type === 'email_success'">
                        <div class="text-center mt-4">
                          <b>{{ $t('account.verify_email.verify_success') }}</b>
                        </div> 
                        <div class="text-center mt-1">{{ $t('account.verify_email.verify_success_thank') }}</div>
                        <!-- <div class="text-center mt-1 mb-4">SMS2PRO</div> -->
                        <hr>
                        <div class="d-flex justify-content-center mt-4">
                          <b-button 
                            class="button-wizard px-5" 
                            variant="primary"
                            @click="actionStep('sign-in')"
                          >
                            {{ $t('account.verify_email.verify_success_signin') }}
                          </b-button>
                        </div>
                      </div>
                      <!-- Verify OTP BY EMAIL verifyed -->
                      <div v-if="verify_type === 'email_verifyed'">
                        <div class="text-center mt-4">
                          <b>{{ $t('account.verify_email.verify_already') }}</b>
                        </div> 
                        <div class="text-center mt-3 mb-5">
                          <p>{{ $t('account.verify_email.verify_success_thank') }}</p>
                          <!-- <b>SMS2PRO</b> -->
                        </div>
                        <hr>
                        <div class="d-flex justify-content-center mt-4">
                          <b-button 
                            class="button-wizard px-5" 
                            variant="primary"
                            :disabled="isReqEmailOtp"
                            @click="actionStep('sign-in')"
                          >
                          {{$t("account.verify_email.verify_success_signin") }} ( {{ timerShowers }} )
                          </b-button>
                        </div>
                      </div>
                    </tab-content>
                  </form-wizard>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import functionCommon from "@/common/functions";
import {mapState} from "vuex";
import Language from "@/components/common/Language.vue";
import VerifyOtp from "@/views/authentication/verifyOTP.vue";
import InputComponent from "@/components/common/inputComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import recaptchaComponent from "@/components/common/recaptchaComponent.vue";
import {validationMixin} from "vuelidate";
import masterData from "@/common/masterData.json";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import profileApi from "@/repository/profileApi";
import {FormWizard, TabContent, WizardStep} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import OtpInput from "@bachdgvn/vue-otp-input";
import moment from "moment/moment";
import settingApi from "@/repository/settingApi";
import OverlayPage from "@/components/common/overlayPage.vue";
import { verify } from "jsonwebtoken";

export default {
  name: "register",
  components: {
    OverlayPage,
    recaptchaComponent,
    SelectComponent,
    InputComponent,
    VerifyOtp,
    Language,
    FormWizard,
    WizardStep,
    TabContent,
    OtpInput
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        searchDelayTimerPhone: null,
        which_service_from: null,
        customer_id: '',
        tracking_channel: null,
        sales: null,
        member_uid: null
      },
      verify_type: 'phone',
      whichServiceList: masterData.whichServiceList,
      dupPhone: '',
      dupEmail: null,
      is_term_checkbox: false,
      is_create_register: false,
      is_req_otp: false,
      package_id: null,
      custom_package: null,
      isVerify: false,
      resetToken: false,
      resetTokenOTP: false,
      ref_code: "",
      token: "",
      tokenReCap: "",
      tokenRecapOTP: "",
      loading_req_otp: false,
      rate_time: 3, //default rate time
      date: moment(this.rate_time, 'minutes'),
      timeCountdown: null,
      setting: JSON.parse(localStorage.getItem('brand_setting')),
      setting_allow_otp: [],
      is_loading_setting: false,
      error_req_otp: '',
      otp_code: '',
      back_step: false,
      back_step_otp: false,
      searchDelayTimer: null,
      searchDelayTimerPhone: null,
      sales_list: [],
      timeRemaining: 0,
      intervalId: null,
      timerShower: this.formatTime(60),
      timerShowers: this.formatTime(30),
      isEmailDisabled: false,
      firstEmail: '',
      errorEmail: '',
      errorEmailFromApi: '',
      isReqEmailOtp: false,
      headerTab: '',
      headerBeforeEdit: '',
      isShowTextEmailVerify: true,
      isShowEdit: false,
      handleEditState: false,
      handleFailState: false
    }
  },
  validations: {
    is_term_checkbox: {
      required,
    },
    form: {
      first_name: {
        required,
        minLength: minLength(2),
      },
      last_name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs('password'),
      },
      which_service_from: {
        required,
      }
    },
  },
  computed: {
    ...mapState({
      secondary_color: state => state.theme.secondaryColor,
    }),
    telState() {
      return functionCommon.telState(this.form.phone)
    },
    telInvalid() {
      const data = functionCommon.telInvalid(this.form.phone)
      return this.$t(data)
    },
    BrandSetting() {
      return this.$store.getters['profile/getBandSettingMedia']
    },
    Banner() {
      return this.$store.getters['profile/getBanner']
    },
    isInvalid() {
      let recaptcha_info = this.$store.getters['profile/getBandSettingMedia']?.recaptcha_information?.for_registration
      return (
          this.$v.form.$invalid ||
          !this.is_term_checkbox ||
          (!this.isVerify && this.token === '') ||
          (recaptcha_info && !this.isVerify && this.token !== '' ||
              recaptcha_info && !this.resetToken && !this.tokenReCap) ||
          !this.telState ||
          this.dupPhone !== '' ||
          this.dupEmail !== ''
      )
    },
    isInvalidReqOtp() {
      let recaptcha_info = this.$store.getters['profile/getBandSettingMedia']?.recaptcha_information?.for_registration
      return recaptcha_info && this.is_req_otp && !this.resetTokenOTP && !this.tokenRecapOTP && this.time === '00:00' && this.back_step ||
          recaptcha_info && this.resetTokenOTP && !this.tokenRecapOTP ||
          recaptcha_info && !this.is_req_otp && this.time === '00:00' && !this.resetTokenOTP && !this.tokenRecapOTP && !this.back_step
    },
    time() {
      return this.date.format('mm:ss');
    }
  },
  created() {
    const ref_id = this.$route.query.ref_id
    const token = this.$route.query.access_token
    const customer_id = this.$route.query.customer_id
    const email = this.$route.query.email
    const first_name = this.$route.query.first_name
    const last_name = this.$route.query.last_name
    const phone = this.$route.query.phone
    const tracking_channel = this.$route.query.tracking_channel || this.getCookie('TRACKING_CHANNEL');
    const member_uid = this.$route.query.member_uid || sessionStorage.getItem('member_uid');
    this.form.which_service_from = this.form.which_service_from !== 'null' ? this.form.which_service_from : null
    if (customer_id) {
      this.form.customer_id = customer_id
    }
    if (email) {
      this.form.email = email
    }
    if (first_name) {
      this.form.first_name = first_name
    }
    if (last_name) {
      this.form.last_name = last_name
    }
    if (phone) {
      this.form.phone = phone
    }
    if (ref_id) {
      this.getRegisterInfo(ref_id)
    } else if (token) {
      const data = {
        strategy: "jwt",
        accessToken: token
      }
      this.login(data)
    }
    if (tracking_channel) {
      this.form.tracking_channel = tracking_channel
      this.setCookie('TRACKING_CHANNEL', this.form.tracking_channel, 30)
    }
    if (member_uid) {
      sessionStorage.setItem('member_uid', member_uid)
      this.form.member_uid = member_uid
    }
    this.package_id = this.getCookie('P_ID')
    this.custom_package = this.getCookie('CUSTOM_PRICE')
  },
  mounted() {
    this.getMasterSalesList()
    this.firstEmail = this.form.email
  },
  methods: {
    getTokenReCap(e, key) {
      if (key === 'register') {
        this.tokenReCap = e
      } else if (key === 'resend') {
        this.tokenRecapOTP = e
      }
    },
    onVerify() {
      this.isVerify = true
    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    checkEmailDup() {
      let lower = this.form.email.toLowerCase()
      const data = {
        type: "email",
        value: lower.trim()
      }
      profileApi.checkDuplicate(data).then((response) => {
        if (response.codeSYS === '001' && !response.success) {
          this.dupEmail = this.$t('common.emailDup')
        } else {
          this.dupEmail = ""
        }
      })
    },
    checkPhoneDup () {
      const data = {
        type: "phone",
        value: this.form.phone
      }
      profileApi.checkDuplicatePhoneNumber(data).then(resp => {
        if (resp.codeSYS === '001' && !resp.success) {
          this.dupPhone = this.$t('common.phoneDup')
        } else {
          this.dupPhone = ""
        }
      }).catch(err => {
        throw err
      })
    },
    actionStep(event) {
      let recaptcha_info = this.$store.getters['profile/getBandSettingMedia']?.recaptcha_information?.for_registration
      let lower = this.form.email.toLowerCase()
      this.form.email = lower.trim()
      if (event === 'sign-in') {
        this.$router.push('Login').catch(() => {
        })
      } else if (event === 'next') {
        this.getBrandSettingOtpType()
      }else if (event === 'back') {
        if (recaptcha_info) {
          window.grecaptcha.reset();
        }
        this.token = null
        this.tokenReCap = null
        this.tokenReCap = null
        this.resetTokenOTP = false
        this.resetToken = false
        clearInterval(this.timeCountdown)
        this.is_req_otp = false
        this.verify_type = 'phone'
        this.back_step = true
        this.$refs.register_props.prevTab()
      } else if (event === 'req-otp') {
        this.reqSendOtp()
      }
    },
    goTerm() {
      const urlPolicy = this.setting?.terms_and_conditions_url
      window.open(urlPolicy, "_blank");
    },
    goPolicy() {
      const urlPolicy = this.setting?.policy_url
      window.open(urlPolicy, "_blank");
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
    getRegisterInfo(ref_id) {
      profileApi.getRegisterInfo(ref_id).then(response => {
        if (response.codeSYS === '001') {
          this.form.first_name = ''
          this.form.last_name = ''
          this.form.phone = response?.phone || ''
          this.form.email = response.email
          this.form.customer_id = response.id
        }
      })
    },
    reqSendOtp() {
      this.is_req_otp = true
      let data = {}
      let recaptcha = this.tokenRecapOTP ? this.tokenRecapOTP : this.tokenReCap
      if (this.form.customer_id) {
        data = {
          phone: this.form.phone,
          email: this.form.email,
          type: this.verify_type,
          recaptcha_token: recaptcha,
          customer_id: this.form.customer_id,
        }
      } else {
        data = {
          phone: this.form.phone,
          email: this.form.email,
          type: this.verify_type,
          recaptcha_token: recaptcha,
        }
      }
      this.loading_req_otp = true
      profileApi.otpRegisterSend(data).then(response => {
        if (response?.codeSYS === '001') {
          this.ref_code = response.ref_code
          this.token = response.transaction_id
          this.is_req_otp = true
          this.resetTokenOTP = false
          this.tokenRecapOTP = null
          this.rate_time = response?.validity
          this.date = moment(this.rate_time, 'minute')
          this.error_req_otp = ''
          this.setTime()
        }
      }).catch(err => {
        if (err?.response?.status !== 429) {
          alert.addNewFailed(this, err.response.data.message)
        }
      }).finally(() => {
        this.resetToken = false
        this.resetTokenOTP = false
        this.loading_req_otp = false
        this.back_step = false
        this.$refs.otpInput.clearInput();
      })
    },
    verifyOTP(otp) {
      let lower = this.form.email.toLowerCase()
      this.is_loading_setting = true
      const data = {
        token: this.token,
        otp_code: otp,
        ref_code: this.ref_code,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: lower.trim(),
        phone: this.form.phone,
        password: this.form.password,
        which_service_from: this.form.which_service_from,
        customer_id: this.form.customer_id,
        type: 'phone',
        tracking_channel: this.form.tracking_channel,
        sales: this.form.sales,
        member_uid: this.form.member_uid
      }
      profileApi.checkOTP(data).then((response) => {
        if (response.data.codeSYS === '001') {
          if (this.form.customer_id === response.data.customer._id) {
            this.verify_type = 'email_success'
            return
          }
          this.$store.dispatch('profile/setUserInfo', response.data)
          alert.addNewSuccess(this)
          this.is_loading_setting = false
          //verify email part{
          this.verify_type = 'email_update'
          this.token = localStorage.getItem('token')
          this.verifyEmail()
          // }
        }
        else {
          this.error_req_otp = response?.message
        }
      }).catch((err) => {
        let code = err?.response?.data?.errors?.code
        this.is_loading_setting = false
        if (code === 436 || code === '436') {
          this.error_req_otp = this.$t('common.OTPDoesNotMatch')
        } else if (code === 1018 || code === '1018') {
          this.error_req_otp = this.$t('common.OTPExpiry')
        } else {
          this.error_req_otp = err?.response?.data?.message
        }
      })
    },
    onChangedHandlerOTP(event) {
      if (event) {
        this.error_req_otp = ''
      }
    },
    startTimer(time) {
      this.timeRemaining = time;
      this.timerShower = this.formatTime(this.timeRemaining, true)
      this.timerShowers = this.formatTime(this.timeRemaining, false)
      this.intervalId = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining <= 0) {
          clearInterval(this.intervalId);
          // หมดเวลาการส่ง กรุณากดส่งลิงก์อีกครั้ง
          this.headerTab = this.$t('account.verify_email.verify_email_timeout')
          this.headerBeforeEdit = this.headerTab
          this.isShowTextEmailVerify = false
          this.isReqEmailOtp = false
          if(time === 60){
            //แสดงปุ่มแก้ไขอีเมล
            this.isShowEdit = true
          }
          if(this.verify_type === 'email_verifyed'){
            //กรณียืนยันตัวตนแล้ว
            this.$router.push('Login').catch(() => {})
          }
        }
        this.timerShower = this.formatTime(this.timeRemaining, true)
        this.timerShowers = this.formatTime(this.timeRemaining, false)
      }, 1000);
    },
    formatTime(seconds, type = true) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if(type){
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
      }
      else{
        return `${String(remainingSeconds)}S`

      }
    },
    verifyEmail(type = false){
      this.isReqEmailOtp = true
      this.errorEmail = ''
      if(!this.handleFailState){
        clearInterval(this.intervalId);
        this.startTimer(60)
      }
      // Re-send ส่งลิ้งก์อีกครั้ง
      if(type){
        if(!this.handleFailState){
          this.isShowTextEmailVerify = true
          this.isShowEdit = false
        }
        this.requestVerifyEmail(true)
      }
      else{
        this.requestVerifyEmail()
      }
    },
    requestVerifyEmail() {
      const body = {
        token: this.token,
        email: this.form.email.toLowerCase(),
      }
      this.firstEmail = body.email
      profileApi.requestEmailOTP(body).then((response) => {
        if (response.data.codeSYS === '001') {
          this.isShowTextEmailVerify = true
          this.handleFailState = false
          this.isShowEdit = false
          this.verify_type = 'email_update'
          const lang = sessionStorage.getItem('lang');
          localStorage.setItem("lang", lang);
          localStorage.setItem("email", body.email);
          this.errorEmail = ''
        }
        else if (response.data?.codeSYS === '428') { // Account Already Verified
          this.verify_type = 'email_verifyed'
          clearInterval(this.intervalId);
          this.startTimer(30)
        }
        else if (response.data?.codeSYS === '301') { //codeSYS == 301 Email นี้เคยใช้สมัครไปแล้ว
          this.isReqEmailOtp = false
          this.handleFailState = true
          setTimeout(() => {
            clearInterval(this.intervalId);
            this.isShowTextEmailVerify = false
            this.verify_type = 'email_update'
            this.headerTab = this.$t('account.verify_email.send_link_fail')
            this.headerBeforeEdit = this.headerTab
            this.isShowEdit = true
          }, 2000)
          //กรณีกดยกเลิกการแก้ไขให้แสดงข้อความ error เดิมก่อนกดมาแก้ไข
          this.errorEmail = this.$t('account.verify_email.email_fail_verified')
          this.errorEmailFromApi = this.$t('account.verify_email.email_fail_verified')
        }
        else { //กรณีอื่นๆ MSG other from BE
          this.isReqEmailOtp = false
          this.handleFailState = true
          setTimeout(() => {
            clearInterval(this.intervalId);
            this.isShowTextEmailVerify = false
            this.verify_type = 'email_update'
            this.headerTab = this.$t('account.verify_email.send_link_fail')
            this.headerBeforeEdit = this.headerTab
            this.isShowEdit = true
          }, 2000)
          this.errorEmail = err?.response?.data?.message
          this.errorEmailFromApi = err?.response?.data?.message
        }
      }).catch((err) => {
        this.handleFailState = true
        setTimeout(() => {
          clearInterval(this.intervalId);
          this.isShowTextEmailVerify = false
          this.verify_type = 'email_update'
          this.headerTab = this.$t('account.verify_email.send_link_fail')
          this.headerBeforeEdit = this.headerTab
          this.isShowEdit = true
          this.isReqEmailOtp = false
        }, 2000)
        this.errorEmail = err?.response?.data?.message
        this.errorEmailFromApi = err?.response?.data?.message
      })
    },
    toggleEdit(){
      this.isShowTextEmailVerify = false
      this.verify_type = 'email_update'
      this.isEmailDisabled =! this.isEmailDisabled
      this.handleEditState = false
      this.headerTab = this.$t('account.verify_email.edit_your_email')
    },
    updateEmail(){
      this.isReqEmailOtp = true
      this.errorEmail = ''
      this.verify_type = 'email_update'
      this.headerTab = this.$t('account.verify_email.system_sending')
      setTimeout(() => {
        this.isEmailDisabled =! this.isEmailDisabled
        clearInterval(this.intervalId);
        this.startTimer(60)
        this.requestVerifyEmail()
      }, 5000)
    },
    cancelUpdate(){
      this.isShowTextEmailVerify = false
      this.verify_type = 'email_update'
      this.headerTab = this.headerBeforeEdit
      this.isEmailDisabled =! this.isEmailDisabled
      this.handleEditState = true
      this.form.email = this.firstEmail
      this.errorEmail = this.errorEmailFromApi
    },
    setTime() {
      let counter = null
      this.timeCountdown = setInterval(() => {
        this.date = moment(this.date.subtract(1, 'seconds'))
        counter = this.date.format('mm:ss')
        if (counter === '00:00') {
          clearInterval(this.timeCountdown)
        }
      }, 1000);
    },
    getBrandSettingOtpType() {
      this.$refs.register_props.nextTab()
      this.is_loading_setting = true
      settingApi.getBrandSetting().then(resp => {
        let notification_type = resp?.notification
        let map_data = {
          phone: notification_type?.sms?.otp?.verify_phone_number,
          email: notification_type?.email?.customer_notification?.verify_email
        }
        const filteredObject = Object.keys(map_data).reduce((acc, key) => {
          if (map_data[key]) {
            acc[key] = map_data[key];
          }
          return acc;
        }, {});
        this.setting_allow_otp = Object.keys(filteredObject)
      }).catch((err) => {
        throw err
      }).finally(() => {
        this.is_loading_setting = false
      })
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "==" + (value || "") + expires + "; path=/";
    },
    handleSearchInput() {
      clearTimeout(this.searchDelayTimer);
      this.searchDelayTimer = setTimeout(() => {
        this.checkEmailDup()
      }, 1000);
    },
    handleEmailInput() {
      if(this.handleEditState){
        return
      }
      this.errorEmail = ''
      // clearTimeout(this.searchDelayTimer);
      // const data = {
      //   type: "email",
      //   value: this.form.email.toLowerCase()
      // }
      // this.searchDelayTimer = setTimeout(() => {
      //   profileApi.checkDuplicate(data).then((response) => {
      //     if (response.codeSYS === '001' && !response.success) {
      //       this.errorEmail = this.$t('account.verify_email.email_edit_duplicate')
      //     } else if(this.firstEmail.toLowerCase() === this.form.email.toLowerCase()){
      //       this.errorEmail = this.$t('account.verify_email.try_new_email')
      //     }
      //     else{
      //       this.errorEmail = ''
      //     }
      //   })
      // }, 1000);
    },
    handleSearchInputPhoneDup() {
      clearTimeout(this.searchDelayTimerPhone);
      this.searchDelayTimerPhone = setTimeout(() => {
        this.checkPhoneDup()
      }, 100);
    },
    getMasterSalesList() {
      profileApi.getMasterSalesList().then(resp => {
        const filter = _.filter(resp, item => {
          return item.is_active === true
        })
        this.sales_list = _.map(filter, item => {
          return {
            text: item?.name,
            value: item?.name
          }
        })
      }).catch(err => {
        throw err
      })
    },
    checkIsOnlyTHEnglish(e) {
      return functionsCommon.isOnlyTHEnglish(e)
    }
  },
  watch: {
    'form.email'(newVal) {
      if (newVal) {
        let lower = newVal.replace(" ", "")
        let trim = lower.toLowerCase()
        this.form.email = trim.trim()
      }
    },
    'form.phone'(newVal) {
      if (newVal) {
        this.dupPhone = ''
      }
    },
    'verify_type'(newVal) {
      if (newVal === 'phone') {
        this.is_req_otp = false
        this.resetToken = false
        this.error_req_otp = ''
        this.resetTokenOTP = false
        this.tokenRecapOTP = null
        clearInterval(this.timeCountdown)
      }
    },
    time(newVal) {
      if (newVal === '00:00') {
        this.error_req_otp = ''
        this.is_req_otp = true
        this.tokenReCap = null
        this.resetTokenOTP = true
        this.resetToken = false
        this.$refs.otpInput.clearInput();
      }
    },
    'setting_allow_otp'(val) {
      if (val) this.verify_type = val[0] || 'phone'
    }
  },
  beforeDestroy() {
    clearInterval(this.timeCountdown)
  },
}
</script>
<style scoped>
.polycy-link {
  color: var(--primary-color);
  cursor: pointer;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#otpInput::v-deep {
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: space-around;
  }
}

#otpInput::v-deep .otp-input-register {
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #E9E9E9;
  font-size: 36px;

  &.error {
    border: 1px solid red !important;
  }

  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}

#otpInput::v-deep .otp-input-register-error {
  border: 1px solid #E9E9E9;
  background: #FFE9E9;
  color: red;
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  font-size: 36px;
  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}

.edit-txt {
  cursor: pointer;
  color: #19CBAC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 250% */ 
}
</style>