<template>
  <b-card>
    <div>
      <b-row align-h="center">
        <h1><b>{{ $t('account.Confirmation') }}</b></h1>
      </b-row>
      <b-row align-h="center">
        <label style="color: #6C727F;">{{ $t('account.EnterOTP') }}
          {{ phone ? 'XXXXXX' + phone.substring(phone.length - 4) : '...' }} (Ref Code : {{ refCode }})</label>
      </b-row>
      <b-row align-h="center">
        <label style="color: #6C727F;"> {{ $t('account.thisCodeExpired') }} <strong
            class="text-primary">{{ time }}</strong></label>
      </b-row>
      <div style="display: flex; justify-content: center;  flex-direction: row;">
        <otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=" "
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            input-type="number"
            @on-change="onChangedHandler"
            @on-complete="onCompleteHandler"
        />
      </div>
      <b-row class="mb-4" align-h="center">
        <span v-show="statusVerify"
              style="color: #F4516C;">{{ time === '00:00' ? $t('common.OTPExpiry') : $t('common.OTPDoesNotMatch') }}</span>
      </b-row>
      <b-row align-h="center" v-show="time==='00:00'">
        <recaptcha-component
            @verify="onVerify"
            :resetToken="resetToken"
            @token="getTokenReCap"
        />
      </b-row>
      <b-row align-h="center">
        <label style="color: #6C727F; font-size: 14px;" v-show="time==='00:00'"> {{ $t('account.DidntReceived') }}
          <strong class="text-gray " v-if="!verified">
            <u>{{ $t('account.Resend') }}</u></strong>
          <strong class="text-green action-button" v-else @click="Resend">
            <u>{{ $t('account.Resend') }}</u></strong></label>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import moment from "moment";
import functionsCommon from "@/common/functions";
import RecaptchaComponent from "@/components/common/recaptchaComponent";

export default {
  name: "verifyOtp",
  components: {
    RecaptchaComponent,
    OtpInput,
  },
  props: {
    phone: {
      type: String,
      request: false
    },
    statusVerify: {
      type: Boolean,
      request: false
    },
    refCode: {
      type: String,
      request: false
    },
  },
  data() {
    return {
      errText: '',
      oldValue: '',
      date: moment(3, 'minutes'),
      Success: true,
      load: false,
      errTextOTP: '',
      timeCountdown: null,
      verified: false,
      resetToken: false,
    }
  },
  computed: {
    time() {
      return this.date.format('mm:ss');
    },
  },
  mounted() {
    this.setTime()
  },
  beforeDestroy() {
    clearInterval(this.timeCountdown)
  },
  methods: {
    onVerify(e) {
      this.verified = e
    },
    onCompleteHandler(code) {
      this.$emit('resetstep')
      this.checkOTP(code)
    },
    onChangedHandler(lastEnteredCode) {
      this.$emit('resetstep')
    },
    getTokenReCap(e) {
      this.$emit('token', e)
    },
    onPasteHandler(code) {
      this.checkOTP(code)
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    setTime() {
      var counter = null
      this.timeCountdown = setInterval(() => {
        this.date = moment(this.date.subtract(1, 'seconds'))
        counter = this.date.format('mm:ss')
        if (counter === '00:00') {
          clearInterval(this.timeCountdown)
        }
      }, 1000);
    },
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    Resend() {
      this.$refs.otpInput.clearInput();
      this.load = true
      this.$emit('resend')
      this.resetToken = true
      this.$emit('resetstep')
      this.date = moment(3, 'minutes')
      clearInterval(this.timeCountdown)
      this.setTime()
    },
    checkOTP(otp) {
      this.$emit('otp', otp)
    },
    backToLogin() {
      this.$router.push('/Login')
    },
  },
}
</script>

<style>
.vue-otp-input >>> .active-input-class {
  text-align: center;
  border-color: var(--secondary-color) !important;
  transform: scale(1);
}

.vue-otp-input >>> .error-class {
  transform: scale(1);
}

.login {
  padding: 70px;
}


.have-value {
  border: 1px solid var(--secondary-color);
}

.otp-false {
  border: 1px solid #FC6C6B;
}

.otp-input {
  width: 40px !important;
  height: 40px !important;
  padding: 5px;
  margin: 0 2px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

}

.otp-input.error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
